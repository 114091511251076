
export function getRedirectLanguage() {
  if (typeof navigator === `undefined`) {
    return "en";
  }

  const lang = navigator && navigator.language && navigator.language.split("-")[0];
  if (!lang) return "en";

  switch (lang) {
    case "es":
      return "es";
    default:
      return "en";
  }
};

export function getCookie(name) {
    const nameEQ = name + "="
    const ca = document.cookie.split(";")
    for (let i = 0; i < ca.length; i++) {
        const c = ca[i]
        while (c.charAt(0) == " ") c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) == 0)
            return c.substring(nameEQ.length, c.length)
    }
    return null
}

export function setCookie(langKey = 'en') {
    const now = new Date()
    let time = now.getTime()
    time += 60 * 60 * 1000
    now.setTime(time)
    document.cookie = `langKey=${langKey}; expires=${now.toUTCString()}; path=/`
}

export async function geoLocation() {
  var endpoint = 'https://api.freegeoip.app/json/?apikey=7715a560-77db-11ec-8e7e-abd7a244ef09'
  try {
    let responseText = await fetch(endpoint)
    let response = await responseText.json()
      if (response.status !== 'success') {
        return 'en'
      }
      if (response.country_code === 'MX') {
        return 'es'
      }
  } catch (error) {
    return false
  }

    return false
  }