import React, { useEffect } from "react";
import { navigate } from "gatsby";
import {
  getRedirectLanguage,
  getCookie,
  setCookie,
  geoLocation
} from '@/components/helpers/languageHelpers'

const IndexPage = () => {
  useEffect(() => {
    async function getLanguage() {
      const langKeyCookie = getCookie("langKey");
      if (langKeyCookie) {
        navigate(`/${langKeyCookie}/`, {replace: true});
      } else {
        const geoLang = await geoLocation()
        if (geoLang) {
          setCookie(geoLang)
          navigate(`/${geoLang}/`, {replace: true});
        } else {
          const urlLang = getRedirectLanguage();
          setCookie(urlLang)
          navigate(`/${urlLang}/`, {replace: true});
        }
      }
    }
    getLanguage()
  }, []);

  return null;
};

export default IndexPage;